/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;900&family=Source+Sans+Pro&display=swap');

@import '~@angular/material/theming';

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$candy-app-primary: mat-palette($mat-indigo);
$candy-app-accent:  mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$candy-app-warn:    mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as `color` or `typography`.
$candy-app-theme: mat-light-theme((
  color: (
    primary: $candy-app-primary,
    accent: $candy-app-accent,
    warn: $candy-app-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($candy-app-theme);


.dashboard-table {
  &.mat-table {
    box-shadow: none;
  }
}

.custom-dialog-container .mat-dialog-container {
  min-width: 768px;
  //min-height: 879px;
  min-height: 620px;
  position: relative;
  overflow-y: auto;
}
.shipping-dialog-container {
  overflow-y: auto;
}
.shipping-dialog-container .mat-dialog-container {
  min-width: 768px;
  //min-height: 879px;
  min-height: 800px;
  position: relative;
  overflow-y: auto;
}

.confirm-dialog-container .mat-dialog-container {
  min-width: 768px;
  //min-height: 879px;
  min-height: 320px;
  position: relative;
  overflow-y: auto;
}

//.small-modal{
//  .modal-wrapper {
//    position: absolute;
//    bottom: 0;
//    min-height: initial;
//    top: initial;
//    height: 50%;
//  }
//}
